import { omit, get } from 'lodash';
import {
  ROUTE_404,
  ROUTE_ACCOUNT_SUSPENDED,
  ROUTE_CATEGORY,
  ROUTE_CATEGORY_CREATE_POST,
  ROUTE_CATEGORY_CREATE_QUESTION,
  ROUTE_COMMENT_DEEP_LINK,
  ROUTE_CREATE_POST,
  ROUTE_CREATE_QUESTION,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_POST,
  ROUTE_POST_EDIT,
  ROUTE_SEARCH,
  ROUTE_DEV_PLAYGROUND,
} from '@wix/communities-forum-client-commons/dist/src/constants/routes';
import { MODAL_TYPE_CREATE_POST } from '../components/modals/post-create-modal/post-create-modal-type';
import { MODAL_TYPE_EDIT_POST } from '../components/modals/post-create-modal/post-edit-modal-type';
import { Router } from '../../common/router';
import { fetchCategory } from '../actions/fetch-category';
import { fetchSubcategories } from '../../common/actions/fetch-categories';
import { fetchCategoryPosts } from '../actions/fetch-category-posts';
import { resetPostTypeFilter } from '../actions/reset-filter';
import { getCurrentUser, isBlocked } from '../../common/store/current-user/current-user-selectors';
import { getIsMobile, isSite, isSSR } from '../../common/store/basic-params/basic-params-selectors';
import { getIsMainPageEnabled } from '../selectors/app-settings-selectors';
import { openModal } from '../../common/modals/framework/store/modal-actions';
import search, { clearSearchResults, MIN_QUERY_LENGTH } from '../actions/search';
import { setIsLoading } from '../actions/set-is-loading';
import { decodeSpaces } from '../services/query-encoding';
import { getQueryParam } from '../services/query-params';
import { fetchPost } from '../actions/fetch-post';
import { getPostBySlug } from '../selectors/post-selectors';
import { getPostTypeFilter } from '../selectors/filter-selectors';
import {
  resolveCommentDeepLinkUrl,
  resetCommentDeepLinkUrl,
} from '../actions/comment-deep-link-actions';
import { getCommentDeepLinkRoutingData } from '../selectors/deep-link-routing-data-selectors';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import { fetchPostPageData } from '../actions/fetch-post-page-data';
import { getDeepLinkParams } from '../services/get-deep-link-params';
import { fetchRepliesToPage } from '../actions/fetch-replies';
import {
  getCategoryBySlug,
  getCategories,
  getCategory,
} from '../../common/selectors/categories-selectors';
import { addErrorState } from '../../common/store/debug-state/debug-state-actions';
import { getPreviousMatches } from '../../common/router/router-selectors';
import { clearComments } from '../actions/clear-comments';
import { fetchFooterPosts } from '../actions/fetch-footer-posts';
import { fetchRelatedPosts } from '../actions/fetch-related-posts';
import { getStyleParams } from '../../common/store/style-params/style-params-selectors';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/dist/src/private/types/ItemTypes';
import { getImageMetadataFromWixMedia, getVideoThumbnailMetadata } from '../services/image-adapter';
import { getLastPage } from '@wix/communities-forum-client-commons/dist/src/services/pagination';
import { getCommentsPerPage, POSTS_PER_PAGE } from '../constants/pagination';
import {
  DISCUSSION,
  QUESTION,
} from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import { FILTER_SHOW_ALL } from '../constants/filtering';
import { getEntityCount } from '../selectors/pagination-selectors';
import contentStateToString from '../services/content-state-to-string';
import { getPostCover } from '@wix/communities-forum-client-commons/dist/src/services/get-post-cover';
import { emitOpenPost } from '../actions/post-socket';
import { incrementPostViewCount } from '../actions/increment-post-view-count';
import { pageOpened } from '../actions/page-opened';
import { getIsDemoMode } from '../../common/store/instance-values/instance-values-selectors';
import { POST_PAGE, FEED_PAGE, CATEGORIES_PAGE, POST_EDIT_PAGE } from '../constants/page-names';
import { emitOpenCategory } from '../actions/category-socket';
import { isMembersOnly } from '@wix/communities-forum-client-commons/dist/src/services/category-privacy-utils';
import { getIsSearchLoading } from '../selectors/is-loading-selectors';
import { getForumSectionUrl } from '../../common/services/get-section-url';
import { buildLink } from '../../common/store/ma-navigation/build-link';
import { getProfileLinkTemplate } from '../../common/store/ma-navigation/ma-navigation-selectors';
import { getBaseUrl, getUrl } from '../../common/store/location/location-selectors';
import { LAYOUT_TYPE_PINBOARD } from '@wix/communities-forum-client-commons/dist/src/constants/layout-types';
import { getLayoutType } from '../selectors/layout-selectors';
import { createPermissionCheckerClientAPI } from '@wix/communities-forum-client-commons/dist/src/services/permission-checker-client-api';
import { getGuestPermissions } from '@wix/communities-forum-universal/dist/src/services/permissions/permissions';
import { UrlMappingsKeys } from '@wix/url-mapper-utils';
import { isExperimentEnabled } from '../selectors/experiments-selectors';
import { EXPERIMENT_SHOW_RESOLVED_IN_TITLE } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';

function logErrorState(store, handler) {
  return error => {
    store.dispatch(addErrorState(error));
    return handler(error);
  };
}

async function handleFallbackRedirect(pathname, redirect, wixCodeApi) {
  const path = await matchCustomPath(wixCodeApi, pathname);
  return redirect(path ? path : ROUTE_404);
}

const assertUserLoggedInAndNotBlocked = (state, redirect) => {
  const currentUser = getCurrentUser(state);
  if (currentUser) {
    if (isBlocked(state)) {
      return { isUserValid: false, redirectState: redirect(ROUTE_ACCOUNT_SUSPENDED) };
    }
  } else {
    return { isUserValid: false, redirectState: redirect(ROUTE_LOGIN) };
  }
  return { isUserValid: true };
};

const createCreatePostRouteHandler = (store, postType) => ({}, redirect) => {
  const state = store.getState();
  const { isUserValid, redirectState } = assertUserLoggedInAndNotBlocked(state, redirect);
  if (!isUserValid) {
    return redirectState;
  }
  if (getIsMobile(state)) {
    store.dispatch(openModal(MODAL_TYPE_CREATE_POST, { postType }));
  }
  if (!isSSR(state)) {
    store.dispatch(pageOpened({ page: POST_EDIT_PAGE }));
  }
};

const createPostEditRouteHandler = store => ({ params }, redirect) => {
  const state = store.getState();
  const { isUserValid, redirectState } = assertUserLoggedInAndNotBlocked(state, redirect);
  if (!isUserValid) {
    return redirectState;
  }
  if (!isSSR(state)) {
    store.dispatch(pageOpened({ page: POST_EDIT_PAGE }));
  }

  if (getIsMobile(state)) {
    store.dispatch(openModal(MODAL_TYPE_EDIT_POST));
  }
  const post = getPostBySlug(state, params.postSlug);
  if (!post) {
    return Promise.all([
      store.dispatch(fetchCategory(params.categorySlug)),
      store.dispatch(fetchPost(params.postSlug)),
    ]);
  }
};

const createCategoryPageHandler = (store, wixCodeApi, compId, config) => (
  { params: { categorySlug, page: _page }, pathname },
  redirect,
) => {
  const state = store.getState();
  const style = getStyle(config, state);
  const isMobile = getIsMobile(state);

  const page =
    !isMobile && getLayoutType(state, style) === LAYOUT_TYPE_PINBOARD
      ? 1
      : parseInt(_page, 10) || 1; // no pagination per url on masonry

  if (!categorySlug) {
    // all posts pseudo category
    return store
      .dispatch(
        fetchCategoryPosts({
          categoryId: null,
          page,
        }),
      )
      .catch(logErrorState(store, () => handleFallbackRedirect(pathname, redirect, wixCodeApi)));
  }

  const category = getCategoryBySlug(state, categorySlug);
  if (isSite(state)) {
    if (category) {
      const entityCount = getEntityCount(state, 'posts', category._id);
      const lastPage = getLastPage(entityCount, POSTS_PER_PAGE);
      const prev = page > 1 ? page - 1 : undefined;
      const next = page < lastPage ? page + 1 : undefined;

      const sectionUrl = getForumSectionUrl(wixCodeApi);
      wixCodeApi.seo.renderSEOTags({
        itemType: ITEM_TYPES.FORUM_CATEGORY,
        itemData: {
          category: {
            ...category,
            title: category.label,
            description: category.description,
            images: category.cover ? [getImageMetadataFromWixMedia(category.cover)] : [],
            videos: [],
            refersTo: `${sectionUrl}/${category.slug}`,
          },
          paging: {
            current: page,
            next,
            prev,
            total: lastPage,
          },
        },
      });
    }
  }

  let promise;

  if (category) {
    promise = Promise.resolve(category);
    store.dispatch(fetchCategory(categorySlug));
  } else {
    promise = store.dispatch(fetchCategory(categorySlug));
  }

  return promise
    .then(category => {
      const promises = [];
      if (category._id) {
        promises.push(store.dispatch(fetchSubcategories(category._id)));
      }
      const state = store.getState();
      const user = getCurrentUser(state);

      const { can } = createPermissionCheckerClientAPI({
        user,
        usersPermissions: user ? user.userPermissions : getGuestPermissions(),
        getCategory: categoryId => getCategory(state, categoryId),
      });

      if (can('read', 'category', category)) {
        const prevCategorySlug = get(getPreviousMatches(state)[1], 'params.categorySlug', null);
        const hasActiveFilter = getPostTypeFilter(state) !== FILTER_SHOW_ALL;
        if (prevCategorySlug !== category.slug && hasActiveFilter) {
          promises.push(store.dispatch(resetPostTypeFilter()));
        }

        promises.push(
          store
            .dispatch(
              fetchCategoryPosts({
                categoryId: category._id,
                page,
              }),
            )
            .then(posts => {
              if (!isSSR(state)) {
                const isMainPageEnabled = getIsMainPageEnabled(state, style);
                store.dispatch(
                  pageOpened({
                    page: FEED_PAGE,
                    category,
                    isMainPageEnabled,
                  }),
                );
                store.dispatch(emitOpenCategory(category._id));
              }
              return posts;
            }),
        );
      }

      return Promise.all(promises);
    })
    .catch(logErrorState(store, () => handleFallbackRedirect(pathname, redirect, wixCodeApi)));
};

const createSearchPageRouter = store => ({ params: { query } }) => {
  if (getIsSearchLoading(store.getState())) {
    // on mobile the search input itself triggers data fetch for perf optimization
    return;
  }
  store.dispatch(clearSearchResults());
  if (query && query.length >= MIN_QUERY_LENGTH) {
    store.dispatch(setIsLoading('search', '', true));
    return store.dispatch(search({ query: decodeSpaces(query) }));
  }
};

const createPostPageRouter = (store, wixCodeApi) => (
  { params: { categorySlug, postSlug, page, deepLinkData }, pathname },
  redirect,
) => {
  const state = store.getState();
  const isServerSideRendering = isSSR(state);
  const category = getCategoryBySlug(state, categorySlug);
  const prevMatches = getPreviousMatches(state);
  const isMobile = getIsMobile(state);
  const hasMovedFromDeeperPageToInitialPage = !page && prevMatches[0] && prevMatches[0].params.page;
  const fetchPostData = () => store.dispatch(fetchPostPageData(postSlug, parseInt(page, 10) || 1));

  if (
    !isMobile &&
    prevMatches[1] && // here 0-th match is the current match
    prevMatches[1].pathname.includes(`${categorySlug}/${postSlug}`)
  ) {
    return fetchPostData();
  }
  if (isMobile && hasMovedFromDeeperPageToInitialPage) {
    // user has moved from n-th page to 1st
    // since comments for mobile are always shown all that are loaded, we need to reset them so for 1st page only 1st page comments are shown
    const post = getPostBySlug(state, postSlug);
    store.dispatch(clearComments(post._id));
  }
  const { parentId, repliesPage } = getDeepLinkParams(deepLinkData);
  const shouldFetchAdditionalReplies = repliesPage && repliesPage !== 1 && parentId;
  const categoryPromise = category
    ? Promise.resolve(category)
    : store.dispatch(fetchCategory(categorySlug));
  const postPromise = fetchPostData();
  return Promise.all([
    categoryPromise,
    postPromise,
    shouldFetchAdditionalReplies
      ? store.dispatch(
          fetchRepliesToPage({
            commentId: parentId,
            postSlug,
            page: repliesPage,
          }),
        )
      : null,
  ])
    .then(results => {
      const category = results[0];
      const post = results[1].post;
      const isCategorySlugDiffers = categorySlug !== category._id && categorySlug !== category.slug;
      const isPostSlugDiffers = postSlug !== post._id && postSlug !== post.slug;
      if (isCategorySlugDiffers || isPostSlugDiffers) {
        return store.dispatch(navigateWithinForum(`/${category.slug}/${post.slug}`));
      }

      if (!isServerSideRendering) {
        store.dispatch(fetchRelatedPosts(post._id));
        store.dispatch(fetchFooterPosts({ categoryId: category._id, excludeSlug: postSlug }));
      }

      const state = store.getState();

      const isAuthenticated = Boolean(state.currentUser);
      if (!isAuthenticated && isMembersOnly(category)) {
        return store.dispatch(navigateWithinForum(`/login?redirect=/${categorySlug}/${postSlug}`));
      }

      if (!isServerSideRendering && !getIsDemoMode(state) && post._id) {
        const url = getUrl(state);
        const origin = getQueryParam(url, 'origin');
        store.dispatch(emitOpenPost(post._id));
        store.dispatch(incrementPostViewCount(post._id));
        store.dispatch(
          pageOpened({
            page: POST_PAGE,
            post,
            origin,
          }),
        );
      }
    })
    .then(() => {
      const state = store.getState();
      if (!isSite(state)) {
        return;
      }

      const isMobile = getIsMobile(state);
      const category = getCategoryBySlug(state, categorySlug);
      if (!category) {
        return;
      }
      const post = getPostBySlug(state, postSlug);
      if (!post) {
        return;
      }
      const postCover = getPostCover(post);
      const page_ = parseInt(page, 10) || 1;
      const entityCount = getEntityCount(state, 'comments', post._id);
      const lastPage = getLastPage(entityCount, getCommentsPerPage(isMobile));
      const prev = page_ > 1 ? page_ - 1 : undefined;
      const next = page_ < lastPage ? page_ + 1 : undefined;

      const image = postCover.imageMetadata
        ? getImageMetadataFromWixMedia(postCover.imageMetadata)
        : null;
      const video = postCover.videoMetadata
        ? getVideoThumbnailMetadata(postCover.videoMetadata)
        : null;

      const content = contentStateToString(post.content);

      const sectionUrl = getForumSectionUrl(wixCodeApi);
      const baseUrl = getBaseUrl(state);
      const postOwnerSiteMemberId = get(post, 'owner.siteMemberId');
      const postOwnerSlug = get(post, 'owner.slug');
      const postTitle = `${
        isExperimentEnabled(state, EXPERIMENT_SHOW_RESOLVED_IN_TITLE) &&
        post.postType === QUESTION &&
        Boolean(post.bestAnswerCommentId)
          ? '(Solved)'
          : ''
      }${post.title}`;

      wixCodeApi.seo.renderSEOTags({
        itemType: ITEM_TYPES.FORUM_POST,
        itemData: {
          post: {
            ...omit(post, 'content'),
            owner: {
              ...post.owner,
              profile: {
                url: postOwnerSiteMemberId
                  ? `${baseUrl}/${buildLink(getProfileLinkTemplate(state), postOwnerSlug)}`
                  : '',
              },
            },
            title: postTitle,
            description: content,
            contentText: content,
            images: image ? [image] : [],
            videos: video ? [video] : [],
            refersTo: `${sectionUrl}/${category.slug}/${post.slug}`,
          },
          category: { label: category.label },
          paging: {
            current: page_,
            next,
            prev,
            total: lastPage,
          },
        },
      });
    })
    .catch(
      logErrorState(store, error => {
        if (error.status === 401) {
          return redirect(ROUTE_LOGIN);
        } else {
          return handleFallbackRedirect(pathname, redirect, wixCodeApi);
        }
      }),
    );
};

const getStyle = (config, state) => {
  const styleParams = { ...config.style.styleParams, ...getStyleParams(state) };
  return { ...config.style, styleParams };
};

const createHomeRouter = (store, config, wixCodeApi, compId) => (router, redirect) => {
  const state = store.getState();
  const style = getStyle(config, state);

  if (isSite(state)) {
    wixCodeApi.seo.renderSEOTags();
  }

  const isMainPageEnabled = getIsMainPageEnabled(state, style);
  if (!isSSR(state)) {
    store.dispatch(pageOpened({ page: CATEGORIES_PAGE, isMainPageEnabled }));
  }
  if (isMainPageEnabled) {
    return store.dispatch(fetchFooterPosts());
  }

  const categories = getCategories(state);
  if (categories.length === 1) {
    return redirect(`/${categories[0].slug}`);
  }

  return createCategoryPageHandler(store, wixCodeApi, compId, config)(router, redirect);
};

const createCommentDeepLinkRouter = store => ({ params }) => {
  const state = store.getState();
  const ssr = isSSR(state);
  const routingData = getCommentDeepLinkRoutingData(state);

  if (routingData) {
    store.dispatch(navigateWithinForum(routingData));
    store.dispatch(resetCommentDeepLinkUrl());
  } else {
    store.dispatch(resolveCommentDeepLinkUrl(params.commentId, true)).then(url => {
      if (!ssr) {
        store.dispatch(navigateWithinForum(url));
        store.dispatch(resetCommentDeepLinkUrl());
      }
    });
  }
};

const createNotFoundPageHandler = wixCodeApi => () => {
  wixCodeApi.seo.setSeoStatusCode(404);
};

const matchCustomPath = async (wixCodeApi, path) => {
  const { key, segments } =
    (wixCodeApi.site &&
      wixCodeApi.site.getCustomizedUrlSegments &&
      (await wixCodeApi.site.getCustomizedUrlSegments(path))) ||
    {};
  switch (key) {
    case UrlMappingsKeys.FORUM_POST:
      return `/${segments.categorySlug}/${segments.postSlug}`;
    case UrlMappingsKeys.FORUM_POST_EDIT:
      return `/${segments.categorySlug}/${segments.postSlug}/edit`;
    case UrlMappingsKeys.FORUM_POST_DEEP_LINK_DATA:
      return `/${segments.categorySlug}/${segments.postSlug}/dl-${segments.deepLinkData}`;
    case UrlMappingsKeys.FORUM_POST_PAGINATION:
      return `/${segments.categorySlug}/${segments.postSlug}/p-${segments.page}`;
    case UrlMappingsKeys.FORUM_POST_PAGINATION_AND_DEEP_LINK_DATA:
      return `/${segments.categorySlug}/${segments.postSlug}/p-${segments.page}/dl-${segments.deepLinkData}`;
    case UrlMappingsKeys.FORUM_CATEGORY:
      return `/${segments.slug}`;
    case UrlMappingsKeys.FORUM_CATEGORY_CREATE_POST:
      return `/${segments.slug}/create-post`;
    case UrlMappingsKeys.FORUM_CATEGORY_CREATE_QUESTION:
      return `/${segments.slug}/create-question`;
    case UrlMappingsKeys.FORUM_CATEGORY_PAGINATION:
      return `/${segments.slug}/p-${segments.page}`;
  }
};

export const createRouter = (store, config, wixCodeApi, compId) => {
  const router = new Router();
  const customRouteHandler = pathname => matchCustomPath(wixCodeApi, pathname);
  router.add(ROUTE_404, createNotFoundPageHandler(wixCodeApi));
  if (process.env.NODE_ENV === 'development') {
    router.add(ROUTE_DEV_PLAYGROUND);
  }
  router.add(ROUTE_SEARCH, createSearchPageRouter(store));
  router.add(ROUTE_LOGIN);
  router.add(ROUTE_ACCOUNT_SUSPENDED);
  router.add(ROUTE_CREATE_POST, createCreatePostRouteHandler(store, DISCUSSION));
  router.add(ROUTE_CREATE_QUESTION, createCreatePostRouteHandler(store, QUESTION));
  router.add(ROUTE_CATEGORY_CREATE_POST, createCreatePostRouteHandler(store, DISCUSSION));
  router.add(ROUTE_CATEGORY_CREATE_QUESTION, createCreatePostRouteHandler(store, QUESTION));
  router.add(ROUTE_POST_EDIT, createPostEditRouteHandler(store));
  router.add(ROUTE_COMMENT_DEEP_LINK, createCommentDeepLinkRouter(store));
  router.add(ROUTE_HOME, createHomeRouter(store, config, wixCodeApi, compId));
  router.add(ROUTE_CATEGORY, createCategoryPageHandler(store, wixCodeApi, compId, config));
  router.add(ROUTE_POST, createPostPageRouter(store, wixCodeApi));
  router.addCustomRouteHandler(customRouteHandler);
  router.fallback(ROUTE_404);
  return router;
};
